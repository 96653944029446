@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Teko:wght@300;400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Teko', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.primary-color {
  color: #ff523d;
}

.bg-primary {
  background-color: #ff523d;
}

h4,
h5,
h6 {
  font-family: 'Teko', sans-serif;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #2f353e;
  font-family: 'Teko', sans-serif;
  font-weight: 500;
  letter-spacing: -0.75px;
}

p {
  font-family: 'Roboto', sans-serif;
}

.roboto{
  font-family: 'Roboto', sans-serif;
}

a.active {
  background: none;
  color: #deba27;
}

.input {
  font-family: 'Roboto', sans-serif !important;
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media only screen and (min-width: 576px) {
  .container {
    max-width: 520px;
  }
}

@media only screen and (min-width: 767px) {
  .container {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media only screen and (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media only screen and (min-width: 1400px) {
  .container {
    max-width: 1320px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}