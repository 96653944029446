.slider{
  height: 250px;
  margin: auto; 
  position: relative;
  width: 90%;
  display: grid;
  place-items: center;
}

.slide-track {
  display: flex;
  width: calc(250px * 28);
  animation: scroll 30s linear infinite;
}
.slide-track:hover {
  animation-play-state: paused;
}

@keyframes scroll {
  0%{
    transform: translateX(0);
  }
  100%{
    transform: translateX(calc(-250px * 9));
  }
}

.slide{
  height: auto;
  width: 130px;
  display: flex;
  align-items: center;
  padding: 15px;
  perspective: 100px;
}

.slide img {
  width: 100%;
  padding-right: 15px;
}