.service {
  margin-top: -50000px;
  -moz-transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
  -ms-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  opacity: 0;
  height: 20px;
}

li .service {
  -moz-transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
  -ms-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  /* opacity: 0; */
}

li {
  -moz-transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
  -ms-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  /* opacity: 0; */
}

li:hover .service {
  margin-top: 0;
  opacity: 1;
  height: auto;
}