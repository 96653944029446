.product-card{
  position: relative;
    margin: auto;
    overflow: hidden;
}
.product-card div img {
  max-width: 100%;
    transition: all 0.3s;
    display: block;
    width: 100%;
    height: auto;
    transform: scale(1);
  transition: all .40s ease-in-out;
}
.product-card:hover div img {
  filter: brightness(50%);
  transform: scale(1.1);
}

.product-card .zoom-icon {
  /* background-color: #FFF; */
  width: 45px;
  height: 45px;
  /* display: inline-block; */
  text-align: center;
  position: absolute;
  top: 45%;
  left: 45%;
  transform: translate(-50% -50%);
  opacity: 0;
  margin-left: -50px;
  cursor: pointer;
  transition: all .40s ease-in-out;
}
.product-card:hover .zoom-icon {
  /* background-color: #deba27; */
  opacity: 1;
  margin-left: 0;
}
.product-card .zoom-icon svg {
  font-size: 17px;
  color: black;
}
.product-card .zoom-icon:hover svg {
  font-size: 17px;
  color: white;
}

#content-wrapper {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: lightgray;
}

#content-wrapper  a {
  display: inline-block;
  text-decoration: none;
  margin: 0 10px;
}

#content-wrapper  a button {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0;
  border: 1px solid;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 10px;
  cursor: pointer;
  color: #444;
}