.production-slider {
  margin: auto;
  position: relative;
  width: 90%;
  display: grid;
  place-items: center;
}

.production-slide-track {
  display: flex;
  width: calc(400px * 50);
  animation: scroll 30s linear infinite;
}

.production-slide-track:hover {
  animation-play-state: paused;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-400px * 9));
  }
}

.production-slide {
  height: auto;
  width: 301px;
  display: flex;
  align-items: center;
  /* padding: 1px; */
  perspective: 100px;
  margin-left: 5px;
  margin-right: 5px;
}

.production-slide img {
  width: 100%;
  transition: ease-in-out 0.8s;
}

.production-slide:hover img {
  filter: brightness(80%);
}

.production-slide .overlay-items {
  display: none;
  margin-top: -100%;
  transition: all ease-in-out 0.8s;
}

.production-slide:hover .overlay-items {
  display: flex;
  margin-top: 0%;
}