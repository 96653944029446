@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.dropdown .dropdown-content {
  background: white;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


/*  */

.service-card-shadow {
  box-shadow: 0px 0px 4px #e3dede;
}

.icon-style {
  transform: rotate(47deg);
}

.card-items-style:hover img {
  transform: scale(1.1);
}

.card-items-style:hover i {
  color: #deba27;
}

.service-thumbnail:before {
  position: absolute;
  top: 0;
  left: -75%;
  display: block;
  content: '';
  width: 30%;
  height: 100%;
  z-index: 1;
  background: rgba(255, 255, 255, 0.3);
  transform: skewX(319deg);
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
  animation-delay: .3s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-name: projectAnimate;
  background: linear-gradient(to right, #ffc10733 0%, #ffc10740 77%, #ffc10733 92%, #ffc10761 100%);
}

.about-thumbnail:before {
  position: absolute;
  top: 0;
  left: -75%;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  z-index: 1;
  background: rgba(255, 255, 255, 0.3);
  transform: skewX(319deg);
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
  animation-delay: .3s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-name: projectAnimate;
  background: linear-gradient(to right, #ffc10733 0%, #ffc10740 77%, #ffc10733 92%, #ffc10761 100%);
}

@keyframes projectAnimate {
  0% {
    left: -75%;
  }

  50% {
    left: 25%;
  }

  100% {
    left: 132%;
  }
}

.widget-nav-menu::before {
  content: '';
  height: 55px;
  width: 5px;
  position: absolute;
  background: #DEBA27;
  top: 50%;
  left: -15px;
  transform: translateY(-50%);
  margin-left: 15px;
}